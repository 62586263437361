<template>
  <div style="background: #FFFFFF; padding: 20px">
    <div style="margin-bottom: 20px">
      <el-input clearable size="small" style="width: 200px; margin-right: 10px; margin-bottom: 10px" v-model="userAccount" placeholder="请输入终端账号"></el-input>
      <el-input clearable size="small" style="width: 200px; margin-right: 10px; margin-bottom: 10px" v-model="userName" placeholder="请输入用户名"></el-input>
      <el-select clearable size="small" style="width: 200px; margin-right: 10px; margin-bottom: 10px" v-model="projectId" placeholder="请选择终端项目">
        <el-option
          v-for="item in projectArray"
          :key="item.id"
          :label="item.pname"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select clearable size="small" style="width: 200px; margin-right: 10px; margin-bottom: 10px" v-model="platform" placeholder="请选择操作端">
        <el-option key="C" label="C端" value="C"></el-option>
        <el-option key="APP" label="APP端" value="APP"></el-option>
        <el-option key="微信" label="微信端" value="微信"></el-option>
      </el-select>
      <el-date-picker
        size="small"
        style="margin-right: 10px; margin-bottom: 10px"
        v-model="daterange"
        value-format="yyyy-MM-dd"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-button @click="search" type="primary" size="small">查询</el-button>
      <el-button @click="exportLogs" type="success" size="small">导出</el-button>
    </div>
    <div>
      <el-table :data="tableData" border style="width: 100%" v-loading="loading">
        <el-table-column :resizable="false" prop="projectName" label="终端项目" align="center" width="180">
        </el-table-column>
        <el-table-column :resizable="false" prop="userAccount" label="终端账号" align="center" width="180">
        </el-table-column>
        <el-table-column :resizable="false" prop="userName" label="用户名" align="center" width="180">
        </el-table-column>
        <el-table-column :resizable="false" prop="operationName" label="操作" align="center">
        </el-table-column>
        <el-table-column :resizable="false" prop="uri" label="URI" align="center">
        </el-table-column>
        <el-table-column :resizable="false" label="操作端" align="center">
          <template scope="{ row }">
            {{ row.platform + '端' }}
          </template>
        </el-table-column>
        <el-table-column :resizable="false" label="时间" align="center" width="180">
          <template scope="{ row }">
            <span>{{ row.createDate.substring(0, 19) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: right; padding-top: 20px; margin-right: -10px">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 50, 100, 200, 300, 500]"
        :page-size="pageSize"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { listTSCOperationLog, exportTSCOperationLog } from '@/api/log'
import { listCP } from '@/api/cProject'
import Templates from '@/views/perceptionReport/components/templatesAdmin'

export default {
  name: 'clog',
  components: { Templates },
  data () {
    return ({
      loading: false,
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      projectArray: [],
      projectId: '',
      userAccount: '',
      userName: '',
      platform: '',
      daterange: '',
      startDate: '',
      endDate: ''
    })
  },
  mounted () {
    this.fetchLogs()
    this.fetchCPList()
  },
  methods: {
    async fetchCPList () {
      const res = await listCP()
      this.projectArray = res.data
    },
    async fetchLogs () {
      if (this.daterange && this.daterange.length === 2) {
        this.startDate = this.daterange[0] + ' 00:00:00'
        this.endDate = this.daterange[1] + ' 23:59:59'
      } else {
        this.startDate = ''
        this.endDate = ''
      }
      this.loading = true
      const res = await listTSCOperationLog({ currentPage: this.currentPage, pageSize: this.pageSize, userAccount: this.userAccount, userName: this.userName, projectId: this.projectId, platform: this.platform, startDate: this.startDate, endDate: this.endDate })
      this.loading = false
      if (res.code === 0) {
        this.tableData = res.data.list
        this.total = res.data.total
      }
    },
    async exportLogs () {
      this.loading = true
      const res = await exportTSCOperationLog({ currentPage: this.currentPage, pageSize: this.pageSize, userAccount: this.userAccount, userName: this.userName, projectId: this.projectId, platform: this.platform, startDate: this.startDate, endDate: this.endDate })
      var blob = new Blob([res])
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement('a')
      a.href = url
      a.download = '导出操作记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
      this.loading = false
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
      this.fetchLogs()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchLogs()
    },
    async search () {
      this.currentPage = 1
      this.fetchLogs()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
