import request from '@/utils/request'
export function listTSCOperationLog (data) {
  return request({
    url: '/sys/log/operation/cpage',
    method: 'POST',
    data
  })
}
export function exportTSCOperationLog (data) {
  return request({
    url: '/sys/log/operation/download-excel',
    method: 'POST',
    responseType: 'blob',
    data
  })
}
